import * as React from "react";
import { useSession } from "@tbml/hooks/useSession";
import { Container } from "@tbml/components/PageLayout";
import { useBreadcrumbs } from "@tbml/hooks/useBreadcrumbs";
import { useRouter } from "@tbml/shared-dependencies/router";
import { CustomerOverview } from "../components/CustomerOverview";

function Home(): JSX.Element {
  const [session, loading] = useSession();
  const [, setBreadcrumbs] = useBreadcrumbs();
  const { pathname } = useRouter();

  React.useEffect(() => {
    setBreadcrumbs(null);
  }, [pathname, setBreadcrumbs]);

  if (loading || !session) {
    return <div>loading...</div>;
  }

  return (
    <Container>
      <CustomerOverview />
    </Container>
  );
}

export default Home;
