import * as React from "react";
import Link from "next/link";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import { gql } from "graphql-request";
import { H1, Text } from "@tbml/components/Typography";
import { Spacer } from "@tbml/components/Spacer";
import { useCustomers } from "@tbml/hooks/useCustomers";
import { Customer } from "@tbml/api-interface/graphql";
import { CustomerOverviewCard } from "../CustomerOverviewCard";
import { CustomerGrid, SpaceBetween } from "./styles";

export const CUSTOMER_OVERVIEW_CARD_IMAGE_WIDTH = 430;
export const CUSTOMER_OVERVIEW_CARD_IMAGE_HEIGHT = 65;

export type Props = {
  includeTestingCustomers?: boolean;
};

export const customersPageCustomerFragmentName = "CustomersPageCustomerFields";
export const customersPageCustomerFragment = gql`
  fragment CustomersPageCustomerFields on Customer {
    id
    name
    inboxEbsId
    brandColor
    executiveBriefingTopics {
      id
      title
    }
    image {
      id
      mediaWidth
      mediaHeight
      originPath
    }
  }
`;

export function CustomerOverview({
  includeTestingCustomers = false,
}: Props): JSX.Element {
  const { query } = useCustomers();
  const { data, status, error } = query({
    fragmentName: customersPageCustomerFragmentName,
    fragment: customersPageCustomerFragment,
  });
  const [searchTerm, setSearchTerm] = React.useState<string>();
  const [showSmokeTestCustomers, setShowSmokeTestCustomers] = React.useState(
    includeTestingCustomers
  );

  const filteredCustomers = React.useMemo<Customer[]>(() => {
    let customers: Customer[] = data ?? [];

    if (searchTerm) {
      customers = customers.filter(
        (customer) =>
          customer.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          customer.inboxEbsId
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          (customer.executiveBriefingTopics ?? []).some((topic) =>
            topic.title.toLowerCase().includes(searchTerm.toLowerCase())
          )
      );
    }
    if (!showSmokeTestCustomers) {
      customers = customers.filter(
        (customer) =>
          !customer.name.startsWith("[Smoke Test:") &&
          !customer.name.startsWith("[Lighthouse Performance Test]")
      );
    }
    return customers;
  }, [searchTerm, data, showSmokeTestCustomers]);

  if (status === "pending") {
    return <Text>Loading...</Text>;
  }
  if (status === "error") {
    return (
      <div>
        <Text>An error occurred when trying to get customers:</Text>
        <pre>{error?.message}</pre>
      </div>
    );
  }
  if (status !== "success") {
    return <Text>There was an indeterminate error</Text>;
  }

  return (
    <div>
      <H1>Select a customer</H1>
      <Spacer />
      <TextField
        fullWidth
        label="Search for a customer"
        aria-label="Customer Search Term"
        variant="standard"
        placeholder="Search for a customer"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
      />
      <Spacer />
      <CustomerGrid>
        {filteredCustomers.map((customer) => (
          <CustomerOverviewCard key={customer.id} customer={customer} />
        ))}
      </CustomerGrid>
      <Spacer />
      <SpaceBetween>
        <Link href="/config" passHref>
          <Button variant="contained" color="primary">
            Create New Customer
          </Button>
        </Link>
        <Tooltip
          title={
            <Text color="inherit">
              Enabling this will include the customers used by the automated
              testing software in the list of available customers.
            </Text>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                value={showSmokeTestCustomers}
                onChange={(e) => {
                  setShowSmokeTestCustomers(e.target.checked);
                }}
              />
            }
            label={<Text>Show testing customers</Text>}
          />
        </Tooltip>
      </SpaceBetween>
    </div>
  );
}
