import * as React from "react";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CardContent,
  CardHeader,
  DialogContentText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import getConfig from "next/config";
import { Link } from "@tbml/components/Link";
import { H6, Text, TextSmall } from "@tbml/components/Typography";
import { Image } from "@tbml/components/Image";
import tokens from "@tbml/tokens";
import { Customer } from "@tbml/api-interface/graphql";
import { useSnackbar } from "@tbml/shared-dependencies/notistack";
import { useCustomers } from "@tbml/hooks/useCustomers";
import { Spacer } from "@tbml/components/Spacer";
import { useStatus } from "../AppBar";
import {
  CUSTOMER_OVERVIEW_CARD_IMAGE_WIDTH,
  CUSTOMER_OVERVIEW_CARD_IMAGE_HEIGHT,
} from "../CustomerOverview";
import { ConfirmDialog } from "../ConfirmDialog";
import {
  CardTopAlignedSection,
  ConfirmDeleteCustomerTextField,
  LeftSection,
  RightSection,
  StyledCard,
  StyledCardActions,
  TopicItem,
  WrappingStack,
} from "./styles";

const config = getConfig();
const { inboxFrontendUrl } = config?.publicRuntimeConfig || {
  inboxFrontendUrl: "https://inbox-frontend-url",
};

const computeSource = (originPath?: string) => {
  if (!originPath) return originPath;
  if (originPath.endsWith(".gif")) return originPath;

  return `${originPath}?fit=clip&auto=enhance`;
};

export function CustomerOverviewCard({
  customer,
  openDeleteModal = false,
  ...props
}: {
  customer: Customer;
  openDeleteModal?: boolean;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [moreOpen, setMoreOpen] = React.useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] =
    React.useState(openDeleteModal);
  const moreAnchorRef = React.useRef<HTMLButtonElement>(null);
  const { setStatus } = useStatus({ persistAfterUnmount: true });
  const [confirmCustomerTextMatch, setConfirmCustomerTextMatch] =
    React.useState(false);

  const {
    deleter: { mutate: deleteCustomers },
  } = useCustomers();

  const deleteOptions = React.useMemo(
    () => ({
      onSuccess: () => {
        setStatus("saved");
        setTimeout(() => {
          setStatus("idle");
        }, 5_000);
      },
      onError: (e: Error) => {
        setStatus("idle");
        enqueueSnackbar(`An error occurred: ${e}`, {
          variant: "error",
        });
      },
    }),
    [enqueueSnackbar, setStatus]
  );

  return (
    <StyledCard {...props}>
      <CardTopAlignedSection aria-label={`Customer card for ${customer.name}`}>
        <CardHeader
          avatar={
            <Link href={`/${customer.id}`}>
              <Avatar
                sx={{
                  bgcolor: customer.brandColor ?? tokens.color.brandDark.value,
                }}
                aria-label="customer"
              >
                {customer.name[0]}
              </Avatar>
            </Link>
          }
          action={
            <Box>
              <IconButton
                aria-label={`Show more actions for customer ${customer.name}`}
                ref={moreAnchorRef}
                onClick={() => {
                  setMoreOpen(true);
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                open={moreOpen}
                onClose={() => {
                  setMoreOpen(false);
                }}
                anchorEl={moreAnchorRef.current}
              >
                <MenuItem
                  title={`Edit ${customer.name}`}
                  aria-label={`Edit customer ${customer.name}`}
                  component="a"
                  href={`/config/${customer.id}`}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDeleteConfirmOpen(true);
                    setMoreOpen(false);
                  }}
                  title={`Delete ${customer.name}`}
                  aria-label={`Delete customer ${customer.name}`}
                >
                  Delete
                </MenuItem>
              </Menu>
              <ConfirmDialog
                open={deleteConfirmOpen}
                isConfirmDisabled={!confirmCustomerTextMatch}
                onClose={() => {
                  setDeleteConfirmOpen(false);
                }}
                onDeclineAction={() => {
                  setDeleteConfirmOpen(false);
                }}
                onConfirmAction={() => {
                  setStatus("saving");
                  deleteCustomers({ ids: [customer.id] }, deleteOptions);
                  setDeleteConfirmOpen(false);
                }}
                dialogOptions={{
                  title: `Delete ${customer.name}?`,
                  confirmText: "Delete",
                  confirmButton: {
                    title: `Confirm deleting ${customer.name}`,
                    ariaLabel: `Confirm deleting customer ${customer.name}`,
                  },
                  declineText: "Cancel",
                  prompt: (
                    <>
                      <DialogContentText>
                        Are you sure you want to delete the customer{" "}
                        <Text paragraph={false} fontWeight="bold">
                          {customer.name}
                        </Text>
                        ? All content for this customer will be permanently
                        removed.
                      </DialogContentText>
                      <Spacer />
                      <ConfirmDeleteCustomerTextField
                        size="small"
                        autoFocus
                        inputProps={{
                          "aria-label": "Customer deletion confirm input field",
                        }}
                        label="Confirm the deletion by writing the customer name"
                        onChange={({ target }) =>
                          setConfirmCustomerTextMatch(
                            target.value === customer.name
                          )
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Escape") {
                            setDeleteConfirmOpen(false);
                            return;
                          }
                          if (e.key !== "Enter") return;
                          if (!confirmCustomerTextMatch) return;

                          setStatus("saving");
                          deleteCustomers(
                            { ids: [customer.id] },
                            deleteOptions
                          );
                          setDeleteConfirmOpen(false);
                        }}
                      />
                    </>
                  ),
                }}
              />
            </Box>
          }
          title={
            <Link href={`/${customer.id}`}>
              <H6>{customer.name}</H6>
            </Link>
          }
          subheader={
            <Link
              href={`${inboxFrontendUrl}/product/${customer.inboxEbsId}`}
              target="_blank"
            >
              <TextSmall color="inherit">
                Inbox Exec. Briefing Product: {customer.inboxEbsId}
              </TextSmall>
            </Link>
          }
        />
        {customer.image && (
          <Link href={`/${customer.id}`}>
            <Image
              src={computeSource(customer.image.originPath)}
              width={CUSTOMER_OVERVIEW_CARD_IMAGE_WIDTH}
              height={CUSTOMER_OVERVIEW_CARD_IMAGE_HEIGHT}
              alt={`banner image for customer ${customer.name}`}
              allowCropping
            />
          </Link>
        )}
        <CardContent>
          <WrappingStack direction="row" spacing={2}>
            {(customer.executiveBriefingTopics ?? []).map((topic) => (
              <Tooltip
                key={topic.id}
                title={<Text color="inherit">{topic.subTitle}</Text>}
              >
                <TopicItem>
                  <Text color="inherit">{topic.title}</Text>
                </TopicItem>
              </Tooltip>
            ))}
          </WrappingStack>
        </CardContent>
      </CardTopAlignedSection>
      <StyledCardActions disableSpacing>
        <LeftSection />
        <RightSection>
          <Link href={`/${customer.id}`}>
            <Button aria-label={`Manage issues for ${customer.name}`}>
              Manage Issues
            </Button>
          </Link>
        </RightSection>
      </StyledCardActions>
    </StyledCard>
  );
}
