import { styled } from "@mui/material";
import tokens from "@tbml/tokens";

export const CustomerGrid = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${tokens.spacing.gutterM.value};
`;

export const SpaceBetween = styled("div")`
  display: flex;
  justify-content: space-between;
`;
