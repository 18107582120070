import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ConfirmDialogProps {
  open: boolean;
  onConfirmAction: () => void;
  onDeclineAction: () => void;
  /**
   * Only fires when the modal is dismissed
   * with Esc or backdrop click. Not when any of the buttons
   * is used.
   */
  onClose?: () => void;
  isConfirmDisabled?: boolean;
  dialogOptions: ConfirmDialogConfig;
}

type ConfirmDialogConfig = {
  title: string;
  prompt: React.ReactNode;
  confirmText: string;
  declineText: string;
  confirmButton?: {
    title?: string;
    ariaLabel?: string;
  };
};

export function ConfirmDialog({
  open,
  onConfirmAction,
  onDeclineAction,
  dialogOptions,
  onClose = () => {},
  isConfirmDisabled = false,
}: ConfirmDialogProps): JSX.Element {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{dialogOptions.title}</DialogTitle>
      <DialogContent>{dialogOptions.prompt}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onConfirmAction}
          autoFocus
          disabled={isConfirmDisabled}
          title={
            dialogOptions.confirmButton?.title
              ? dialogOptions.confirmButton.title
              : "Confirm operation"
          }
          aria-label={
            dialogOptions.confirmButton?.ariaLabel
              ? dialogOptions.confirmButton.ariaLabel
              : "Confirm operation"
          }
        >
          {dialogOptions.confirmText}
        </Button>
        <Button onClick={onDeclineAction}>{dialogOptions.declineText}</Button>
      </DialogActions>
    </Dialog>
  );
}
